@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Nunito Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.uppercase {
  font-family: 'Work Sans', sans-serif !important;
}

.worksans{
  font-family: 'Work Sans', sans-serif !important;
}

.bg-radial{
  background: radial-gradient(120.74% 71.95% at 12.18% 94.44%, #A6C4D9 0%, rgba(32, 39, 48, 0.52) 58.8%, rgba(14, 16, 22, 0.5) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  opacity: 0.44;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.bg-card{
  background: linear-gradient(359.72deg, #050C0F 8.73%, rgba(6, 13, 17, 0.8) 70.06%, #000000 98.57%);
}
.card{
  background: linear-gradient(359.72deg, #050C0F 8.73%, rgba(6, 13, 17, 0.8) 70.06%, #000000 98.57%);
  /* opacity: 0.66; */
  border-radius: 16px;
}


.bg-pos{
  background: linear-gradient(0.65deg, #0C1A20 9.29%, rgba(9, 20, 27, 0.8) 50.23%, #10191E 98.55%);
}

.hexagon {
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
}